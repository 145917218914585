import { createApp } from 'vue'
import App from './App.vue'
import { router } from './routes/router';
import NowUiKit from './plugins/now-ui-kit';
import { plugin, defaultConfig } from '@formkit/vue';
import HighchartsVue from 'highcharts-vue'

const app = createApp(App)

app.config.productionTip = false;
app.use(NowUiKit);
app.use(router);
app.use(plugin, defaultConfig({
    theme: 'genesis' // will load from CDN and inject into document head
}));
app.use(HighchartsVue)

app.mount('#app');
