// https://www.schachbund.de/wertungsordnung-3-berechnungsregeln-des-dwz-wertungssystems/articles/wertungsordnung-36-die-turnierleistung.html

let gaussian = require('gaussian');
const mean = 0
const variance = 80000
let distribution = gaussian(mean, variance);

let turnierleistung = {
    logDis(x) {
        // alpha = 0
        // beta = 400/ln(10)
        return 1/(1 + Math.pow(10, -x/400))
    },

    getW_e(R_0, R_opponents) {
        let W_e = 0
        for (let i in R_opponents) {
            W_e += distribution.cdf(R_0 - R_opponents[i])
            // W_e += this.logDis(R_0 - R_opponents[i])
        }
        return W_e.toFixed(3)
    },

    // https://www.schachbund.de/wertungsordnung-anhang-1-besondere-berechnungsverfahren/articles/wertungsordnung-anhang-11-gesamtiteration-fuer-die-erst-dwz-erwerber.html
    performanceIteration(R_c, R_opponents, W, n, firstDWZ=false) {
        let D
        let p = W/n
        let R_p = R_c + distribution.ppf(p)
        let R_i = R_p
        for (let i=0; i<20; i++) {  // max of 20 iterations
            let W_ep = this.getW_e(R_p, R_opponents)
            p = 0.5 + (W - W_ep) / n
            D = distribution.ppf(p)
            R_p = R_p + D

            // https://www.schachbund.de/wertungsordnung-3-berechnungsregeln-des-dwz-wertungssystems/articles/wertungsordnung-34-spieler-ohne-wertungszahl.html
            if (firstDWZ) {
                R_i = R_i + D
                if (R_p <= 800) {
                    R_i = 700 + R_p / 8
                }
            }

            if (D < 1) break
        }
        return [R_p, R_i]
    },

    getR_c(R_opponents) {
        const sum = R_opponents.reduce((partialSum, a) => partialSum + a, 0);
        return (sum / R_opponents.length) || 0;
    },

    getR_h(R_0, R_opponents, W, n) {
        const R_c = this.getR_c(R_opponents)
        const W_e = this.getW_e(R_0, R_opponents)

        // https://www.schachbund.de/wertungsordnung-3-berechnungsregeln-des-dwz-wertungssystems/articles/wertungsordnung-36-die-turnierleistung.html
        if (W === 0) {
            return [R_c - 677, R_c, W_e]
        }
        if (W === n) {
            return [R_c + 677, R_c, W_e]
        }

        // iteration
        const [R_h, _] = this.performanceIteration(R_c, R_opponents, W, n)

        return [R_h, R_c, W_e]
    },
}

export default turnierleistung