// https://www.schachbund.de/wertungsordnung-anhang-1-besondere-berechnungsverfahren/articles/wertungsordnung-anhang-12-die-ermittlung-des-entwicklungskoeffizienten-e.html
// https://www.schachbund.de/wertungsordnung-anhang-2-tabellen/articles/wertungsordnung-anhang-25-die-aenderungskonstante-k.html
// https://www.schachbund.de/wertungsordnung-anhang-3-auswertungsbeispiele-mit-varianten/articles/wertungsordnung-anhang-31-auswertung-eines-beispielturniers.html

import entwicklungskoeffizient from '@/algo/entwicklungskoeffizient.js'
import turnierleistung from '@/algo/turnierleistung.js'


// test values
// let R_0 = 2038  // old dwz
// let R_opponents = [1947, 1995, 1947, 1882, 2097, 2015, 2013, 1918]
//// let R_c = 1977  // opponent's mean dwz
// let W = 6  // points
//// let W_e = 4.669  // expected points
// let n = 8  // rounds
// let i = 6  // count of tournament

// let age = 27


let dwz = {
    getNewDWZ(R_0, R_opponents, W, n, age, i) {
        let [R_h, R_c, W_e] = turnierleistung.getR_h(R_0, R_opponents, W, n)

        let E = entwicklungskoeffizient.getE(R_0, age, W, W_e, i)  // Entwicklungskoeffizient

        let K = 800 / (E + n) // Änderungskonstante
        let R_n = R_0 + K * (W - W_e)   // new dwz

        return {
            "expectation": W_e,
            "niveau": R_c.toFixed(0),
            "performance": R_h.toFixed(0),
            "newDWZ": R_n.toFixed(0).toString()
        }
    },
    getFirstDWZ(R_opponents, W, n) {
        let R_c = turnierleistung.getR_c(R_opponents)
        let [R_h, R_i] = turnierleistung.performanceIteration(R_c, R_opponents, W, n, true)

        return {
            "expectation": W,
            "niveau": R_c.toFixed(0),
            "performance": R_h.toFixed(0),
            "newDWZ": R_i.toFixed(0).toString()
        }
    }
}

export default dwz