<template>
  <div class="wrapper blog-post">
    <div>
      <div class="section">
        <div class="container">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <h2 class="title">
                DWZ
              </h2>
              <p>
                Ein DWZ-Rechner ist ein nützliches Online-Tool, mit dem du deine DWZ anhand deiner Turnierergebnisse berechnen kannst.
                Du kannst auch verschiedene Szenarien durchspielen, um zu sehen, wie sich zukünftige Ergebnisse auf deine
                DWZ auswirken könnten.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <h3 class="title">
                Was ist DWZ?
              </h3>
              <p>
                Die Deutsche Wertungszahl (DWZ) ist ein Wertungssystem, das die Spielstärke von Schachspielern in Deutschland misst.
                Es ist dem internationalen Elo-System ähnlich, wird jedoch vom Deutschen Schachbund betrieben und verwendet
                eine eigene Formel zur Berechnung der Wertungszahlen.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <h3 class="title">
                Wie bekomme ich DWZ?
              </h3>
              <p>
                Die DWZ wird aus den Ergebnissen von Turnieren und Wettkämpfen berechnet, an denen der Spieler teilgenommen hat.
                Ob beim Turnier die DWZ ausgewertet wird, ist gewöhnlich aus der Ausschreibung zu entnehmen.
                Der Ligabetrieb wird generell DWZ ausgewertet.
                Bei einer Erstauswertung müssen mindestens 5 ausgewertete Spiele vorliegen.
                Bei 0 aus 5 und 5 aus 5 kann keine Erst-DWZ bestimmt werden.
                Es muss also mindestens mal ein halber Punkt abgegeben bzw. mindestens einmal ein halber Punkt erziel worden sein.
              </p>
              <p>
                Regelmäßiges Spielen und die Teilnahme an Turnieren ist der beste Weg, um deine DWZ zu verbessern.
                Je mehr Partien du spielst, desto besser wirst du und desto genauer wird deine DWZ deine tatsächliche
                Spielstärke widerspiegeln.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <h3 class="title">
                Warum ist die DWZ wichtig?
              </h3>
              <p>
                Die DWZ ist wichtig, da sie es Spielern ermöglicht, ihre Fortschritte im Schach zu verfolgen und ihre Spielstärke zu messen.
                Außerdem wird die DWZ verwendet, um Spieler in verschiedenen Ranglisten zu platzieren und um sicherzustellen,
                dass sie gegen Spieler mit ähnlicher Spielstärke antreten.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

  export default {}
</script>
<style>
</style>
