<template>
  <div class="wrapper blog-post">
    <div>
      <div class="section">
        <div class="container">
          <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
              <h2 class="title">
                DWZ Verteilung
              </h2>
              <h5 class="description">
                Die DWZ Verteilung bietet eine klare und übersichtliche Darstellung der Stärke im Vergleich zu anderen Spielern.
                Du kannst sehen, wie du im Verhältnis zu anderen Spielern in ganz Deutschland abschneidest.
              </h5>
            </div>
            <highcharts :options="mainChartOptions"></highcharts>
          </div>
            <div class="row">
              <div class="col-md-8 ml-auto mr-auto">
                <h5 class="description">
                  Die Veränderung über die Zeit hinweg zeigt einen Rückgang der Spieler im Club Level Bereich.
                </h5>
              </div>
            </div>
          <highcharts :options="historyChartOptions"></highcharts>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import {Chart} from 'highcharts-vue'

  export default {
    components: {
      highcharts: Chart
    },
    data() {
      return {
        mainChartOptions: {
          chart: {
            type: 'areaspline',
            height: '600px'
          },
          title: {
            text: '',
            align: 'left'
          },
          subtitle: {
            text: 'Source: <a href="https://www.schachbund.de/wertungszahlen.html" target="_blank">DSB Spieler Datenbank</a> <s>(22.04.2023)</s>',
            align: 'left'
          },
          xAxis: {
            title: {
              text: 'DWZ'
            }
          },
          yAxis: {
            title: {
              text: 'Spieler'
            }
          },
          plotOptions: {
            areaspline: {
              fillOpacity: 0.5
            }
          },
          series: [{
            name: "Anzahl Spieler",
            data: [
              [500, 0],
              [525, 1],
              [550, 0],
              [575, 0],
              [600, 1],
              [625, 5],
              [650, 13],
              [675, 43],
              [700, 99],
              [725, 404],
              [750, 835],
              [775, 1028],
              [800, 508],
              [825, 565],
              [850, 564],
              [875, 545],
              [900, 577],
              [925, 609],
              [950, 660],
              [975, 631],
              [1000, 676],
              [1025, 727],
              [1050, 747],
              [1075, 718],
              [1100, 811],
              [1125, 838],
              [1150, 863],
              [1175, 922],
              [1200, 997],
              [1225, 1138],
              [1250, 1116],
              [1275, 1282],
              [1300, 1235],
              [1325, 1325],
              [1350, 1342],
              [1375, 1498],
              [1400, 1563],
              [1425, 1692],
              [1450, 1591],
              [1475, 1744],
              [1500, 1775],
              [1525, 1774],
              [1550, 1872],
              [1575, 1780],
              [1600, 1801],
              [1625, 1762],
              [1650, 1853],
              [1675, 1768],
              [1700, 1686],
              [1725, 1725],
              [1750, 1689],
              [1775, 1571],
              [1800, 1625],
              [1825, 1514],
              [1850, 1430],
              [1875, 1314],
              [1900, 1217],
              [1925, 1168],
              [1950, 1158],
              [1975, 1002],
              [2000, 904],
              [2025, 806],
              [2050, 721],
              [2075, 651],
              [2100, 517],
              [2125, 500],
              [2150, 379],
              [2175, 339],
              [2200, 313],
              [2225, 277],
              [2250, 265],
              [2275, 184],
              [2300, 158],
              [2325, 169],
              [2350, 123],
              [2375, 119],
              [2400, 110],
              [2425, 86],
              [2450, 75],
              [2475, 55],
              [2500, 89],
              [2525, 36],
              [2550, 39],
              [2575, 44],
              [2600, 21],
              [2625, 23],
              [2650, 17],
              [2675, 12],
              [2700, 12],
              [2725, 7],
              [2750, 4],
              [2775, 1],
              [2800, 0],
              [2825, 1],
              [2850, 0],
            ]
          }]
        },
        historyChartOptions: {
          chart: {
            type: 'areaspline',
            height: '600px'
          },
          title: {
            text: '',
            align: 'left'
          },
          subtitle: {
            text: 'Source: <a href="https://www.schachbund.de/wertungszahlen.html" target="_blank">DSB Spieler Datenbank</a> <s>(22.04.2023)</s>',
            align: 'left'
          },
          xAxis: {
            title: {
              text: 'DWZ'
            }
          },
          yAxis: {
            title: {
              text: 'Spieler'
            }
          },
          plotOptions: {
            areaspline: {
              fillOpacity: 0.5
            }
          },
          series: [
            {
              name: "2017",
              data: [
                [500, 0],
                [525, 1],
                [550, 0],
                [575, 0],
                [600, 1],
                [625, 3],
                [650, 8],
                [675, 68],
                [700, 182],
                [725, 524],
                [750, 1162],
                [775, 1227],
                [800, 650],
                [825, 692],
                [850, 674],
                [875, 685],
                [900, 702],
                [925, 730],
                [950, 732],
                [975, 720],
                [1000, 783],
                [1025, 819],
                [1050, 853],
                [1075, 854],
                [1100, 914],
                [1125, 964],
                [1150, 985],
                [1175, 993],
                [1200, 1161],
                [1225, 1264],
                [1250, 1264],
                [1275, 1300],
                [1300, 1343],
                [1325, 1434],
                [1350, 1538],
                [1375, 1585],
                [1400, 1670],
                [1425, 1838],
                [1450, 1921],
                [1475, 1835],
                [1500, 1833],
                [1525, 1921],
                [1550, 1936],
                [1575, 2013],
                [1600, 1996],
                [1625, 1940],
                [1650, 1948],
                [1675, 1849],
                [1700, 1849],
                [1725, 1761],
                [1750, 1732],
                [1775, 1717],
                [1800, 1602],
                [1825, 1560],
                [1850, 1396],
                [1875, 1367],
                [1900, 1263],
                [1925, 1243],
                [1950, 1125],
                [1975, 1030],
                [2000, 950],
                [2025, 835],
                [2050, 720],
                [2075, 634],
                [2100, 548],
                [2125, 450],
                [2150, 441],
                [2175, 321],
                [2200, 254],
                [2225, 265],
                [2250, 213],
                [2275, 186],
                [2300, 150],
                [2325, 119],
                [2350, 118],
                [2375, 127],
                [2400, 77],
                [2425, 80],
                [2450, 77],
                [2475, 61],
                [2500, 42],
                [2525, 42],
                [2550, 41],
                [2575, 32],
                [2600, 36],
                [2625, 17],
                [2650, 18],
                [2675, 17],
                [2700, 10],
                [2725, 3],
                [2750, 8],
                [2775, 4],
                [2800, 3],
                [2825, 1],
                [2850, 0],
              ]

            },
            {
              name: "2018",
              data: [
                [500, 0],
                [525, 1],
                [550, 0],
                [575, 0],
                [600, 1],
                [625, 3],
                [650, 15],
                [675, 50],
                [700, 179],
                [725, 502],
                [750, 1133],
                [775, 1186],
                [800, 650],
                [825, 699],
                [850, 685],
                [875, 677],
                [900, 703],
                [925, 733],
                [950, 731],
                [975, 741],
                [1000, 767],
                [1025, 819],
                [1050, 843],
                [1075, 847],
                [1100, 888],
                [1125, 961],
                [1150, 1006],
                [1175, 1046],
                [1200, 1160],
                [1225, 1249],
                [1250, 1264],
                [1275, 1279],
                [1300, 1355],
                [1325, 1416],
                [1350, 1572],
                [1375, 1593],
                [1400, 1638],
                [1425, 1791],
                [1450, 1875],
                [1475, 1891],
                [1500, 1877],
                [1525, 1841],
                [1550, 1921],
                [1575, 1936],
                [1600, 1985],
                [1625, 1945],
                [1650, 1874],
                [1675, 1854],
                [1700, 1823],
                [1725, 1818],
                [1750, 1695],
                [1775, 1670],
                [1800, 1652],
                [1825, 1549],
                [1850, 1487],
                [1875, 1360],
                [1900, 1243],
                [1925, 1230],
                [1950, 1157],
                [1975, 1052],
                [2000, 898],
                [2025, 844],
                [2050, 748],
                [2075, 642],
                [2100, 528],
                [2125, 450],
                [2150, 448],
                [2175, 340],
                [2200, 298],
                [2225, 264],
                [2250, 186],
                [2275, 201],
                [2300, 155],
                [2325, 153],
                [2350, 123],
                [2375, 98],
                [2400, 93],
                [2425, 64],
                [2450, 65],
                [2475, 54],
                [2500, 53],
                [2525, 51],
                [2550, 46],
                [2575, 42],
                [2600, 36],
                [2625, 27],
                [2650, 15],
                [2675, 18],
                [2700, 9],
                [2725, 10],
                [2750, 5],
                [2775, 3],
                [2800, 1],
                [2825, 1],
                [2850, 0],
              ]

            },
            {
              name: "2019",
              data: [
                [500, 0],
                [525, 0],
                [550, 0],
                [575, 0],
                [600, 1],
                [625, 3],
                [650, 13],
                [675, 54],
                [700, 163],
                [725, 492],
                [750, 1078],
                [775, 1220],
                [800, 626],
                [825, 671],
                [850, 674],
                [875, 683],
                [900, 717],
                [925, 743],
                [950, 753],
                [975, 775],
                [1000, 744],
                [1025, 814],
                [1050, 836],
                [1075, 861],
                [1100, 898],
                [1125, 963],
                [1150, 1015],
                [1175, 1035],
                [1200, 1174],
                [1225, 1267],
                [1250, 1257],
                [1275, 1316],
                [1300, 1332],
                [1325, 1459],
                [1350, 1562],
                [1375, 1596],
                [1400, 1669],
                [1425, 1773],
                [1450, 1814],
                [1475, 1836],
                [1500, 1923],
                [1525, 1873],
                [1550, 1959],
                [1575, 1887],
                [1600, 2028],
                [1625, 1875],
                [1650, 1824],
                [1675, 1864],
                [1700, 1892],
                [1725, 1764],
                [1750, 1745],
                [1775, 1682],
                [1800, 1668],
                [1825, 1547],
                [1850, 1473],
                [1875, 1310],
                [1900, 1290],
                [1925, 1271],
                [1950, 1115],
                [1975, 1049],
                [2000, 983],
                [2025, 857],
                [2050, 715],
                [2075, 641],
                [2100, 581],
                [2125, 450],
                [2150, 406],
                [2175, 355],
                [2200, 319],
                [2225, 276],
                [2250, 215],
                [2275, 200],
                [2300, 148],
                [2325, 133],
                [2350, 129],
                [2375, 105],
                [2400, 88],
                [2425, 82],
                [2450, 74],
                [2475, 64],
                [2500, 51],
                [2525, 44],
                [2550, 48],
                [2575, 42],
                [2600, 35],
                [2625, 31],
                [2650, 14],
                [2675, 16],
                [2700, 9],
                [2725, 6],
                [2750, 8],
                [2775, 0],
                [2800, 1],
                [2825, 2],
                [2850, 0],
              ]
            },
            {
              name: "2020",
              data: [
                [500, 0],
                [525, 0],
                [550, 0],
                [575, 0],
                [600, 1],
                [625, 5],
                [650, 12],
                [675, 59],
                [700, 148],
                [725, 506],
                [750, 1050],
                [775, 1219],
                [800, 587],
                [825, 686],
                [850, 652],
                [875, 656],
                [900, 705],
                [925, 735],
                [950, 761],
                [975, 743],
                [1000, 778],
                [1025, 790],
                [1050, 833],
                [1075, 819],
                [1100, 907],
                [1125, 928],
                [1150, 979],
                [1175, 1032],
                [1200, 1151],
                [1225, 1217],
                [1250, 1214],
                [1275, 1357],
                [1300, 1324],
                [1325, 1426],
                [1350, 1506],
                [1375, 1605],
                [1400, 1603],
                [1425, 1757],
                [1450, 1782],
                [1475, 1794],
                [1500, 1909],
                [1525, 1866],
                [1550, 1868],
                [1575, 1979],
                [1600, 1954],
                [1625, 1836],
                [1650, 1849],
                [1675, 1805],
                [1700, 1841],
                [1725, 1761],
                [1750, 1713],
                [1775, 1721],
                [1800, 1652],
                [1825, 1503],
                [1850, 1436],
                [1875, 1334],
                [1900, 1272],
                [1925, 1242],
                [1950, 1125],
                [1975, 1062],
                [2000, 977],
                [2025, 827],
                [2050, 758],
                [2075, 587],
                [2100, 582],
                [2125, 457],
                [2150, 413],
                [2175, 328],
                [2200, 284],
                [2225, 278],
                [2250, 251],
                [2275, 194],
                [2300, 184],
                [2325, 138],
                [2350, 126],
                [2375, 112],
                [2400, 101],
                [2425, 83],
                [2450, 78],
                [2475, 52],
                [2500, 64],
                [2525, 41],
                [2550, 51],
                [2575, 49],
                [2600, 29],
                [2625, 30],
                [2650, 29],
                [2675, 18],
                [2700, 12],
                [2725, 0],
                [2750, 9],
                [2775, 3],
                [2800, 1],
                [2825, 1],
                [2850, 0],
              ]
            },
            {
              name: "2021",
              data: [
                [500, 0],
                [525, 0],
                [550, 0],
                [575, 0],
                [600, 1],
                [625, 5],
                [650, 14],
                [675, 50],
                [700, 147],
                [725, 493],
                [750, 1019],
                [775, 1127],
                [800, 607],
                [825, 670],
                [850, 676],
                [875, 634],
                [900, 639],
                [925, 759],
                [950, 713],
                [975, 719],
                [1000, 724],
                [1025, 820],
                [1050, 790],
                [1075, 826],
                [1100, 913],
                [1125, 932],
                [1150, 990],
                [1175, 967],
                [1200, 1110],
                [1225, 1169],
                [1250, 1234],
                [1275, 1344],
                [1300, 1336],
                [1325, 1413],
                [1350, 1531],
                [1375, 1560],
                [1400, 1625],
                [1425, 1716],
                [1450, 1721],
                [1475, 1843],
                [1500, 1768],
                [1525, 1896],
                [1550, 1896],
                [1575, 1918],
                [1600, 1921],
                [1625, 1849],
                [1650, 1861],
                [1675, 1745],
                [1700, 1842],
                [1725, 1718],
                [1750, 1767],
                [1775, 1644],
                [1800, 1662],
                [1825, 1530],
                [1850, 1457],
                [1875, 1296],
                [1900, 1263],
                [1925, 1205],
                [1950, 1167],
                [1975, 1028],
                [2000, 939],
                [2025, 853],
                [2050, 709],
                [2075, 660],
                [2100, 534],
                [2125, 499],
                [2150, 399],
                [2175, 325],
                [2200, 313],
                [2225, 269],
                [2250, 251],
                [2275, 183],
                [2300, 177],
                [2325, 142],
                [2350, 129],
                [2375, 117],
                [2400, 103],
                [2425, 80],
                [2450, 66],
                [2475, 63],
                [2500, 56],
                [2525, 49],
                [2550, 48],
                [2575, 44],
                [2600, 34],
                [2625, 34],
                [2650, 16],
                [2675, 20],
                [2700, 11],
                [2725, 6],
                [2750, 5],
                [2775, 2],
                [2800, 2],
                [2825, 1],
                [2850, 0],
              ]
            },
            {
              name: "2022",
              data: [
                [500, 0],
                [525, 0],
                [550, 0],
                [575, 0],
                [600, 1],
                [625, 5],
                [650, 11],
                [675, 41],
                [700, 120],
                [725, 437],
                [750, 903],
                [775, 1049],
                [800, 565],
                [825, 610],
                [850, 569],
                [875, 545],
                [900, 602],
                [925, 678],
                [950, 664],
                [975, 654],
                [1000, 668],
                [1025, 753],
                [1050, 747],
                [1075, 795],
                [1100, 838],
                [1125, 863],
                [1150, 920],
                [1175, 898],
                [1200, 1072],
                [1225, 1107],
                [1250, 1180],
                [1275, 1229],
                [1300, 1266],
                [1325, 1331],
                [1350, 1429],
                [1375, 1468],
                [1400, 1534],
                [1425, 1657],
                [1450, 1672],
                [1475, 1764],
                [1500, 1732],
                [1525, 1801],
                [1550, 1839],
                [1575, 1865],
                [1600, 1893],
                [1625, 1773],
                [1650, 1838],
                [1675, 1669],
                [1700, 1789],
                [1725, 1672],
                [1750, 1759],
                [1775, 1564],
                [1800, 1615],
                [1825, 1467],
                [1850, 1452],
                [1875, 1271],
                [1900, 1256],
                [1925, 1224],
                [1950, 1106],
                [1975, 1010],
                [2000, 953],
                [2025, 827],
                [2050, 695],
                [2075, 607],
                [2100, 555],
                [2125, 501],
                [2150, 413],
                [2175, 304],
                [2200, 326],
                [2225, 269],
                [2250, 223],
                [2275, 223],
                [2300, 160],
                [2325, 145],
                [2350, 137],
                [2375, 104],
                [2400, 105],
                [2425, 82],
                [2450, 67],
                [2475, 62],
                [2500, 66],
                [2525, 50],
                [2550, 51],
                [2575, 36],
                [2600, 29],
                [2625, 34],
                [2650, 19],
                [2675, 13],
                [2700, 10],
                [2725, 6],
                [2750, 2],
                [2775, 3],
                [2800, 1],
                [2825, 2],
                [2850, 0],
              ]
            },
            {
            name: "2023",
            data: [
              [500, 0],
              [525, 1],
              [550, 0],
              [575, 0],
              [600, 1],
              [625, 5],
              [650, 13],
              [675, 43],
              [700, 99],
              [725, 404],
              [750, 835],
              [775, 1028],
              [800, 508],
              [825, 565],
              [850, 564],
              [875, 545],
              [900, 577],
              [925, 609],
              [950, 660],
              [975, 631],
              [1000, 676],
              [1025, 727],
              [1050, 747],
              [1075, 718],
              [1100, 811],
              [1125, 838],
              [1150, 863],
              [1175, 922],
              [1200, 997],
              [1225, 1138],
              [1250, 1116],
              [1275, 1282],
              [1300, 1235],
              [1325, 1325],
              [1350, 1342],
              [1375, 1498],
              [1400, 1563],
              [1425, 1692],
              [1450, 1591],
              [1475, 1744],
              [1500, 1775],
              [1525, 1774],
              [1550, 1872],
              [1575, 1780],
              [1600, 1801],
              [1625, 1762],
              [1650, 1853],
              [1675, 1768],
              [1700, 1686],
              [1725, 1725],
              [1750, 1689],
              [1775, 1571],
              [1800, 1625],
              [1825, 1514],
              [1850, 1430],
              [1875, 1314],
              [1900, 1217],
              [1925, 1168],
              [1950, 1158],
              [1975, 1002],
              [2000, 904],
              [2025, 806],
              [2050, 721],
              [2075, 651],
              [2100, 517],
              [2125, 500],
              [2150, 379],
              [2175, 339],
              [2200, 313],
              [2225, 277],
              [2250, 265],
              [2275, 184],
              [2300, 158],
              [2325, 169],
              [2350, 123],
              [2375, 119],
              [2400, 110],
              [2425, 86],
              [2450, 75],
              [2475, 55],
              [2500, 89],
              [2525, 36],
              [2550, 39],
              [2575, 44],
              [2600, 21],
              [2625, 23],
              [2650, 17],
              [2675, 12],
              [2700, 12],
              [2725, 7],
              [2750, 4],
              [2775, 1],
              [2800, 0],
              [2825, 1],
              [2850, 0],
            ]
            }]
        }
      }
    }
  }
</script>
<style>
</style>
