import { createRouter, createWebHistory } from 'vue-router'

import Navbar from "../layout/NavbarMain.vue";
import Footer from "../layout/FooterMain.vue";

import Landing from '../pages/LandingPage.vue';
import Dwz from '../pages/DwzPage.vue';
import Stats from '../pages/StatsPage.vue';
import Profile from '../pages/ProfilePage.vue';
import Imprint from '../pages/ImprintPage.vue';


const routes = [
  {
    path: '/',
    name: 'index',
    components: {
      default: Landing,
      header: Navbar,
      footer: Footer
    },
    props: {
      header: {colorOnScroll: 0},
      footer: {backgroundColor: 'black'}
    }
  },
  {
    path: '/dwz',
    name: 'dwz',
    components: {
      default: Dwz,
      header: Navbar,
      footer: Footer
    },
    props: {
      header: {colorOnScroll: 0},
      footer: {backgroundColor: 'black'}
    }
  },
  {
    path: '/stats',
    name: 'stats',
    components: {
      default: Stats,
      header: Navbar,
      footer: Footer
    },
    props: {
      header: {colorOnScroll: 0},
      footer: {backgroundColor: 'black'}
    }
  },
  {
    path: '/imprint',
    name: 'imprint',
    components: {
      default: Imprint,
      header: Navbar,
      footer: Footer
    },
    props: {
      header: { colorOnScroll: 400 },
      footer: { backgroundColor: 'black' }
    }
  }
]

export const router = createRouter({
  linkExactActiveClass: 'active',
  history: createWebHistory(),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  }
});
