<template>
  <div class="wrapper landing-page">
    <div class="section section-about-us">
      <div class="section section-about-us" style="padding-top: 0px">
        <div class="container">
          <div class="row">
            <div class="ml-auto mr-auto text-left">
              <h2 class="title">
                DWZ Rechner
              </h2>
              <h5 class="description">
                Die DWZ (Deutsche Wertungszahl) ist ein Wertungssystem im Schach, das in Deutschland und anderen Ländern verwendet wird, um die Spielstärke von Spielern zu bewerten.
                Die DWZ basiert auf dem ELO-System. Die DWZ ist jedoch eine modifizierte Version des ELO-Systems, das speziell für den deutschen Schachverband entwickelt wurde.
              </h5>
              <!--              <h5>-->
              <!--                And that excites me the most!-->
              <!--              </h5>-->
            </div>
          </div>

          <div class="row">
            <!--            <p class="title">-->
            <!--              Eigene DWZ-->
            <!--            </p>-->
            <FormKit
                type="group"
                v-model="player"
            >
              <div class="inputs">
                <FormKit
                    type="number"
                    label="DWZ"
                    name="dwz"
                    validation="number"
                />
                <FormKit
                    type="select"
                    name="age"
                    label="Alter"
                    :options="age"
                    validation="required"
                />
                <FormKit
                    type="number"
                    label="Runden"
                    name="rounds"
                    validation="required|number"
                    v-on:click="getPointOptions"
                />
                <FormKit
                    type="select"
                    name="index"
                    label="Auswertungen"
                    :options="indexOptions"
                    validation="required"
                />
              </div>
            </FormKit>
          </div>
<!--                    <pre wrap>{{ player }}</pre>-->

          <div class="row">
            <div class="inputs">
              <label class="formkit-label">
                Gegner DWZ
              </label>
            </div>
            <FormKit
                type="group"
                v-model="opponents"
            >
              <div class="opponent">
                <FormKit
                    v-for="n in Number(player.rounds)"
                    type="number"
                    :name="n.toString()"
                    validation="number"
                />
              </div>
            </FormKit>
<!--            <pre wrap>{{ opponents }}</pre>-->

          </div>
          <div class="row">
            <FormKit
                type="select"
                label="Punkte"
                name="points"
                :options="pointsOptions"
                validation="required"
                v-model="points"
            />
          </div>
<!--          <pre wrap>{{ points }}</pre>-->

          <div class="row">
            <h5 class="title">
              Ergebnis
            </h5>
            <h5 class="description">
              Grundlage für die Berechnung ist eine Normalverteilung, die als Gauß’sche Glockenkurve bekannt ist.
              Für die Berechnung wird die Gewinnerwartung bestimmt. Darauf basierend errechnet sich die neue Wertungszahl.
            </h5>
            <FormKit
                type="group"
                v-model="result"
            >
              <div class="result">
                <FormKit
                    type="text"
                    name="expectation"
                    label="Erwartung"
                    validation="number"
                    readonly="true"
                />
                <FormKit
                    type="number"
                    name="niveau"
                    label="Niveau"
                    validation="number"
                    readonly="true"
                />
                <FormKit
                    type="number"
                    name="performance"
                    label="Leistung"
                    validation="number"
                    readonly="true"
                />
              </div>
              <FormKit
                  type="number"
                  name="newDWZ"
                  label="Neue DWZ"
                  validation="number"
                  readonly="true"
              />
            </FormKit>
          </div>
<!--                    <pre wrap>{{ result }}</pre>-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dwz from '@/algo/dzw.js'

export default {
  name: 'LandingPage',
  bodyClass: 'landing-page',
  data() {
    return {
      player: {
        "dwz": null,
        "age": "26",
        "rounds": "5",
        "index": "6"
      },
      opponents: {},
      opponentCount: 0,
      points: 0,
      result: null,
      dwz: 0,
      age: [
        {label: "bis 20", value: "15"},
        {label: "21 - 25", value: "23"},
        {label: "über 25", value: "26"},
      ],
      pointsOptions: [
        {label: "0", value: "0"}
      ],
      indexOptions: [
        {label: "0", value: "0"},
        {label: "1", value: "1"},
        {label: "2", value: "2"},
        {label: "3", value: "3"},
        {label: "4", value: "4"},
        {label: "5", value: "5"},
        {label: "über 5", value: "6"}
      ]
    }
  },
  methods: {
    // getAgeOptions: function () {
    //   for (let i = 0; i < 100; i++) {
    //     this.age.push({label: i.toString(), value: i.toString()})
    //   }
    // },
    countOfItems: function(items) {
      let count = 0
      for (let item in items) {
        if (typeof items[item] !== 'undefined') {
          count++
        }
      }
      return count
    },
    getPointOptions: function () {
      this.pointsOptions = []
      let score
      let newOpponentCount = this.countOfItems(this.opponents)
      for (let i = 0; i <= newOpponentCount * 2; i++) {
        score = i * 0.5
        this.pointsOptions.push({label: score.toString(), value: score.toString()})
      }
      this.points = (Number(this.points) + (newOpponentCount - this.opponentCount) * 0.5).toString()
      this.opponentCount = newOpponentCount
    },
    getDWZ: function () {
      let R_0
      if (this.player.dwz == null) {
        R_0 = 0
      } else {
        R_0 = Number(this.player.dwz)
      }
      let R_opponents = []
      for (let i in this.opponents) {
        let value = this.opponents[i]
        if (typeof value !== 'undefined') {
          value = Number(value)
          if (value >= 400 && value <= 4000) {
            R_opponents.push(Number(this.opponents[i]))
          }
        }
      }
      const W = Number(this.points)
      const n = R_opponents.length
      const age = Number(this.player.age)
      const i = 6

      if (n === 0) {
        this.result = {
          "expectation": 0,
          "niveau": 0,
          "performance": 0,
          "newDWZ": R_0
        }
      } else if (R_0 === 0) {
        this.result = dwz.getFirstDWZ(R_opponents, W, n)
      } else {
        this.result = dwz.getNewDWZ(R_0, R_opponents, W, n, age, i)
      }
    },
    cleanOpponents: function () {
      for (let i in this.opponents) {
        if (this.opponents[i] === "") {
          delete this.opponents[i]
        }
      }
    }
  },
  beforeMount() {
    // this.getAgeOptions()
    this.getPointOptions()
  },
  watch: {
    player: {
      handler: function (val, oldVal) {
        this.getDWZ()
      },
      deep: true
    },
    opponents: {
      handler: function (val, oldVal) {
        this.getPointOptions();
        this.getDWZ();
        this.cleanOpponents();
      },
      deep: true
    },
    points: function () {
      this.getDWZ();
    }
  }
}
</script>
<style>
.inputs {
  display: flex;
  justify-content: space-between;
}

.inputs > .formkit-outer {
  width: calc(23.7% + 0.8em);
}

.opponent {
  display: flex;
  justify-content: space-between;
}

.opponent > .formkit-outer {
  width: calc(100% / (v-bind('player.rounds') + .1));
}

.result {
  display: flex;
  justify-content: space-between;
}

.result > .formkit-outer {
  width: calc(50% - 0.5em);
}
</style>
