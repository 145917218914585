// https://www.schachbund.de/wertungsordnung-anhang-1-besondere-berechnungsverfahren/articles/wertungsordnung-anhang-12-die-ermittlung-des-entwicklungskoeffizienten-e.html
let entwicklungskoeffizient = {
    // Term J based on age
    getJ(age) {
        if (age <= 20) {
            return 5
        } else if (age <= 25) {
            return 10
        } else if (age > 25) {
            return 15
        } else {
            throw new Error('Error in getJ');
        }
    },

    // Grundwert E_0
    getE_0(R_0, age) {
        return Math.pow(R_0/1000, 4) + this.getJ(age)
    },

    // Beschleunigungsfaktor
    getF_B(R_0, age, W, W_e) {
        if (age <= 20 && W >= W_e) {
            let f_B = R_0/2000
            if (f_B < 0.5) {
                return 0.5
            } else if (f_B > 1) {
                return 1
            } else {
                return f_B
            }
        } else {
            return 1
        }
    },

    // Bremszuschlag
    getS_Br(R_0, W, W_e) {
        if (R_0 < 1300 && W < W_e) {
            return Math.exp((1300-R_0)/150) - 1
        } else {
            return 0
        }
    },

    // Entwicklungskoeffizient
    getE(R_0, age, W, W_e, i) {
        let S_Br = this.getS_Br(R_0, W, W_e)
        let E =  this.getE_0(R_0, age) * this.getF_B(R_0, age, W, W_e) + S_Br

        E = Math.round(E)

        let maxE
        if (S_Br === 0) {
            maxE = Math.min(30, 5*i)
        } else if (S_Br > 0 ) {
            maxE = 150
        } else {
            throw new Error('Error: F_B is negative');
        }

        if (E < 5) {
            return 5
        } else if (E > maxE) {
            return maxE
        } else {
            return E
        }
    }
}

export default entwicklungskoeffizient