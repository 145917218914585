<template>
  <navbar-component
    position="fixed"
    type="primary"
    :transparent="transparent"
    :color-on-scroll="colorOnScroll"
    menu-classes="ml-auto"
    ref="expandNav"
  >
    <template v-slot:navbar-brand-slot>
      <el-popover
        placement="bottom-start"
        :width="180"
        trigger="hover"
        content="Inoffizieller DWZ Rechner"
      >
        <template #reference>
          <router-link class="navbar-brand" to="/" @click="scrollToTop()">
            DWZ Rechner
          </router-link>
        </template>
      </el-popover>
    </template>

    <template v-slot:navbar-menu>
      <li class="nav-item">
        <router-link to="/dwz" class="nav-link" @click="closeHandlerNav">
          <i class="now-ui-icons design-2_ruler-pencil"></i>
          <p>Über DWZ</p>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/stats" class="nav-link" @click="closeHandlerNav">
          <i class="now-ui-icons  business_chart-bar-32"></i>
          <p>Statistiken</p>
        </router-link>
      </li>
<!--      <li class="nav-item">-->
<!--        <a href="https://www.buymeacoffee.com/twdatasciew" class="nav-link" @click="closeHandlerNav">-->
<!--          <img src="/icons/coffee-cup.png" alt="Buy me a coffee" width="16" height="13">-->
<!--          <p>Buy me a coffee</p>-->
<!--        </a>-->
<!--      </li>-->

    </template>
  </navbar-component>
</template>


<script>
import NavbarComponent from '../components/Navbar/NavbarComponent.vue';
import DropDown from '../components/Navbar/DropDown.vue';
import NavLink from '../components/Navbar/NavLink.vue';

export default {
  props: {
      transparent: Boolean,
    colorOnScroll: Number
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0,0);
    },
    closeHandlerNav() {
      this.$refs.expandNav.close()
    },
    swipeHandlerNav (direction) {
      //console.log(direction);  // May be left / right / top / bottom
      if(this.$refs.expandNav.showMenu===true && direction==="right") {
        this.$refs.expandNav.toggle()
      } else if(this.$refs.expandNav.showMenu===false && direction==="left"){
        this.$refs.expandNav.toggle()
      }
    }
  },
  components: {
    NavbarComponent,
    DropDown,
    NavLink
  }
};
</script>

<style scoped></style>
